body {
  box-sizing: border-box;
  justify-content: center;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  opacity: 0.9;
}

button:hover {
  background-color: none;
}

.css-1qsxih2 {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.css-1n0nj1r {
  height: 0px;
  width: 0px;
}

.css-isbt42 > .MuiGrid-item {
  padding-left: 0;
}

div.root {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100vw;
  align-self: center;
  background-color: #25262a;
}

div.calendar {
  min-height: 0px;
  border-radius: 50px;
  border-color: #25262a;
  width: 100%;
  background-color: #25262a;

  justify-self: center;
  padding-top: 1rem;
}
div.float-right.color-fg-muted {
  margin-bottom: 6px;
}

div.css-17usifj-MuiPaper-root {
  box-shadow: 0px 2px 10px rgb(0 0 0 / 5%), 0px 1px 1px rgb(0 0 0 / 10%);
}
svg.css-i4bv87-MuiSvgIcon-root {
  fill: #25262a;
}

.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input {
  color: rgba(245, 245, 245, 0.9);
}

.css-810veu-MuiContainer-root {
  padding-right: 0px;
  padding-left: 0px;
}

.css-1oqqzyl-MuiContainer-root {
  width: 100%;
  margin-left: 0;
  box-sizing: border-box;
  margin-right: 0;
  display: block;
  padding-left: 0;
  padding-right: 0;
}

.MuiFormLabel-root {
  color: rgba(245, 245, 245, 0.9);
}
.css-mhc70k-MuiGrid-root > .MuiGrid-item {
  padding-left: 0px;
}

.css-15tg1zj-MuiPaper-root .float-left,
.float-right {
  color: rgba(245, 245, 245, 0.9);
}
.contrib-number,
.text-muted,
.css-u08h2f-MuiPaper-root,
.css-1aeybn4-MuiPaper-root {
  color: rgba(245, 245, 245, 0.9);
  font-size: 1rem;
}

.css-19qh49k-MuiGrid-root {
  z-index: 20;
  background-color: #f5eec2;
}

.contrib-number {
  font-size: 1.6rem;
}

.css-61gif9-MuiContainer-root {
  margin: 0 auto;
}

.pulse-button {
  box-shadow: 0 0 0 0 rgba(whitesmoke, 0.5);
  -webkit-animation: pulse 1.5s infinite;
  animation: pulse 1.8s infinite;
}

.pulse-button:hover {
  -webkit-animation: none;
  animation: none;
}

.github-paper:hover {
  cursor: pointer;
}

#typewriter {
  color: red;
  transform: translateX(444px);
}

a {
  font-size: 2rem;
  font-style: italic;
  color: rgba(245, 245, 245, 0.9);
}

.udemy-photo {
  margin-left: 5.9rem;
  margin-top: 1.2rem;
  height: 8rem;
  padding: 1.2rem;
}

.btn-3 {
  box-shadow: 1px 1px 2px rgba(#fff, 0.2);
  transition: all 150ms linear;
}

.btn-3:hover {
  opacity: 0.7;
  box-shadow: inset -7px 0px 12px -8px rgba(0, 0, 0, 0.3),
    inset 7px 0px 12px -8px rgba(0, 0, 0, 0.3);
  transition: all 250ms linear;
  border-radius: 30px;
}

.brainstorm-button:hover {
  cursor: pointer;
}

.brainstorm-button:active {
  box-shadow: inset -7px 0px 12px -8px rgba(0, 0, 0, 0.3),
    inset 7px 0px 12px -8px rgba(0, 0, 0, 0.3);
  transform: translateX(12px);
  transform: translateY(2px);
}

#design-words {
  color: rgba(245, 245, 245, 0.9);
}

.js-button {
  width: 65vw;
  height: auto;
}
.codecademy-photo {
  height: auto;
  width: 484px;
  margin-top: 2.6rem;
  margin-left: 1.5rem;
  padding: 1.2rem;
}

.codecademy-photo-modal {
  height: auto;
  width: 390px;
  display: flex;
  justify-self: center;
  align-self: center;
  padding-top: 0.2rem;
}

.cdlink {
  color: blue;
  font-size: 6vw;
}

.solidity {
  background-color: #f2f2f2;
  border-radius: 10px;
}

img.sql {
  transform: scale(1.9); /* Scales the image by a factor of 2 */
  /*transform-origin: 0 0; /* Scales from the top-left corner */
}

img.crewai {
  transform: scale(1.4); /* Scales the image by a factor of 2 */
  /*transform-origin: 0 0; /* Scales from the top-left corner */
}

.google-photo {
  width: 14rem;
  height: 14rem;
  margin-top: 7rem;
  margin-right: 1.8rem;
  padding: 1.2rem;
}

.google-photo-modal {
  height: auto;
  width: 200px;
  display: flex;
  justify-self: center;
  align-self: center;
  padding-top: 0.2rem;
}

.MuiLinearProgress-determinate {
  display: flex;
  justify-self: center;
}

#slideshow {
  margin: 0 auto;
  padding-top: 50px;
  height: 600px;
  width: 100%;
  box-sizing: border-box;
}

/*ADD SHADOWS OPTIONAL*/
.square-flip .square .boxshadow,
.square-flip .square .textshadow,
.square-flip .square2 .boxshadow,
.square-flip .square2 .textshadow {
  -ms-transition: 0.6s;
  transition: 0.6s;
  -webkit-transition: 0.6s;
}

.square-flip .square .boxshadow {
  -webkit-box-shadow: 24px 42px 58px -8px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 24px 42px 58px -8px rgba(0, 0, 0, 0.3);
  box-shadow: 24px 42px 58px -8px rgba(0, 0, 0, 0.3);
}
.square-flip .square .textshadow {
  -webkit-text-shadow: 24px 42px 58px -8px rgba(0, 0, 0, 0.3);
  -moz-text-shadow: 24px 42px 58px -8px rgba(0, 0, 0, 0.3);
  text-shadow: 24px 42px 58px -8px rgba(0, 0, 0, 0.3);
}
.square-flip:hover .square .boxshadow,
.square-flip:hover .square .textshadow {
  -webkit-box-shadow: 240px 42px 58px -8px rgba(0, 0, 0, 0);
  -moz-box-shadow: 240px 42px 58px -8px rgba(0, 0, 0, 0);
  box-shadow: 240px 42px 58px -8px rgba(0, 0, 0, 0);
}

.textshadow {
  justify-self: center;
  align-self: center;
  transform: translateY(5px);
}

.square-flip .square2 .boxshadow {
  -webkit-box-shadow: 240px 42px 58px -8px rgba(0, 0, 0, 0);
  -moz-box-shadow: 240px 42px 58px -8px rgba(0, 0, 0, 0);
  box-shadow: 240px 42px 58px -8px rgba(0, 0, 0, 0);
}
.square-flip .square2 .textshadow {
  -webkit-text-shadow: 240px 42px 58px -8px rgba(0, 0, 0, 0);
  -moz-text-shadow: 240px 42px 58px -8px rgba(0, 0, 0, 0);
  text-shadow: 240px 42px 58px -8px rgba(0, 0, 0, 0);
}
.square-flip:hover .square2 .boxshadow,
.square-flip:hover .square2 .textshadow {
  -webkit-box-shadow: 24px 42px 58px -8px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 24px 42px 58px -8px rgba(0, 0, 0, 0.3);
  box-shadow: 24px 42px 58px -8px rgba(0, 0, 0, 0.3);
}

.slider-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.slider-list {
  text-align: center !important;
  align-items: center;
}

div.slide {
  margin: 0 2.5vw;
}

div.slider-frame {
  display: flex;
  padding-top: 5vw;
  padding-bottom: 5vw;
}

.entire-content {
  margin: auto;
  width: 190px;
  perspective: 1000px;
  position: relative;
  padding-top: 120px;
}

.content-carrousel {
  width: 100%;
  position: absolute;
  float: right;
  animation: rotar 15s infinite linear;
  transform-style: preserve-3d;
}

.content-carrousel:hover {
  animation-play-state: paused;
  cursor: pointer;
}

.content-carrousel figure {
  width: 100%;
  height: 12vw;
  /* border: 1px solid #3b444b; */
  overflow: hidden;
  position: absolute;
}

.content-carrousel figure:nth-child(1) {
  transform: rotateY(0deg) translateZ(300px);
}
.content-carrousel figure:nth-child(2) {
  transform: rotateY(40deg) translateZ(300px);
}
.content-carrousel figure:nth-child(3) {
  transform: rotateY(80deg) translateZ(300px);
}
.content-carrousel figure:nth-child(4) {
  transform: rotateY(120deg) translateZ(300px);
}
.content-carrousel figure:nth-child(5) {
  transform: rotateY(160deg) translateZ(300px);
}
.content-carrousel figure:nth-child(6) {
  transform: rotateY(200deg) translateZ(300px);
}
.content-carrousel figure:nth-child(7) {
  transform: rotateY(240deg) translateZ(300px);
}
.content-carrousel figure:nth-child(8) {
  transform: rotateY(280deg) translateZ(300px);
}
.content-carrousel figure:nth-child(9) {
  transform: rotateY(320deg) translateZ(300px);
}
.content-carrousel figure:nth-child(10) {
  transform: rotateY(360deg) translateZ(300px);
}

.shadow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* box-shadow: 0px 0px 20px 0px #000; */
  border-radius: 10px;
}

.content-carrousel img {
  image-rendering: auto;
  transition: all 300ms;
  width: 100%;
  height: 100%;
}

.content-carrousel img:hover {
  transform: scale(1.2);
  transition: all 300ms;
}

@keyframes rotar {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

#pro {
  font-size: 1.4rem;
  padding-top: 2px;
  padding-left: 2px;
  padding-bottom: 0px;
  padding-right: 6px;
  font-weight: bold;
  background-color: #25262a;
}
#guage {
  align-self: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  fill: #39395f;
}

.keys {
  width: 880px;
  display: block;
  margin: 35vh auto;
}

.key__button {
  box-sizing: border-box;
  line-height: 80px;
  letter-spacing: 3px;
  font-size: 2rem;
  text-align: center;
  width: 160px;
  color: #555;
  cursor: pointer;
  margin: 0 8px;
  height: 80px;
  border-color: #f2f2f2;
  border-style: solid;
  text-shadow: 0 0.5px 1px #777, 0 2px 6px #f2f2f2;
  border-width: 1px;
  border-radius: 10px;
  background: -webkit-linear-gradient(
    top,
    #f9f9f9 0%,
    #d2d2d2 80%,
    #c0c0c0 100%
  );
  font-family: sans-serif;
  display: inline-block;
  position: relative;
  z-index: 10;
  transform: translateY(-220px) translateX(12.5vw);
  transition: box-shadow 0.3s ease, transform 0.15s ease;
  box-shadow: 0 0 1px #888, 0 1px 0 #fff, 0 6px 0 #c0c0c0,
    0 8px 17px rgba(68, 68, 68, 0.4), 2px 1px 4px rgba(68, 68, 68, 0.25),
    -2px 1px 4px rgba(68, 68, 68, 0.25), 0 9px 16px rgba(68, 68, 68, 0.1);
}

.key__button:hover,
.key__button:focus {
  box-shadow: 0 0 1px #888, 0 1px 0 #fff, 0 4px 0 #c0c0c0,
    0 2px 35px rgba(68, 68, 68, 0.3), 2px 2px 4px rgba(68, 68, 68, 0.25),
    -2px 2px 4px rgba(68, 68, 68, 0.25), 0 7px 4px rgba(68, 68, 68, 0.1);
  transform: translateY(-218px) translateX(12.5vw);
}

.key__button:active {
  box-shadow: 0 0 1px #888, 0 1px 0 #fff, 0 0 0 #c0c0c0,
    0 0px 30px rgba(68, 68, 68, 0.15), 2px 2px 4px rgba(68, 68, 68, 0.25),
    -2px 2px 4px rgba(68, 68, 68, 0.25), 0 0px 4px rgba(68, 68, 68, 0.25);
  transform: translateY(-216px) translateX(12.5vw);
}

.css-118zhtq-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
  background-color: rgba(0, 0, 0, 0);
  width: 0px;
  height: 0px;
}

.css-1ib5mj-MuiContainer-root {
  max-width: 100vw;
  padding: 0;
}

.css-mhc70k-MuiGrid-root {
  width: 100vw;
  padding: 0;
  margin: 0;
}

.flip-horizontal-fwd {
  animation: flip-horizontal-fwd 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both;
}

.text-pop-up-top {
  -webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: text-pop-up-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.text-pop-up-top-scroll-arrow {
  -webkit-animation: text-pop-up-top-scroll-arrow 2s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: text-pop-up-top-scroll-arrow 2s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-iteration-count: infinite;
}

.text-pop-up-top-no-depth {
  -webkit-animation: text-pop-up-top-no-depth 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: text-pop-up-top-no-depth 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}

.rotate-scale-down {
  -webkit-animation: rotate-scale-down 0.65s linear both;
  animation: rotate-scale-down 0.65s linear both;
}

.front-end {
  -webkit-animation: front-end 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: front-end 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.dxg-background-range {
  fill: #39395f;
}

.scroll-arrow {
  transform: rotate(180deg);
  cursor: pointer;
}

.scroll-arrow:target {
  opacity: 0;
}

/*clouds-1*/

#cloud {
  background: rgba(255, 255, 255);
  width: 210px;
  height: 70px;
  border-radius: 150px;
  box-shadow: 10px 10px rgba(0, 0, 0, 0.2);
  animation: move 3s infinite;
  z-index: -2;
}

#cloud:after {
  content: "";
  background: rgba(255, 255, 255);
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  top: -50px;
  left: 50px;
}

#cloud:before {
  content: "";
  background: rgba(255, 255, 255);
  position: absolute;
  width: 170px;
  height: 150px;
  border-radius: 50%;
  top: -90px;
  right: 40px;
}

/*clouds-2*/

#cloud-2 {
  background: rgba(255, 255, 255);
  width: 350px;
  height: 140px;
  border-radius: 150px;
  box-shadow: 10px 10px rgba(0, 0, 0, 0.2);
  animation: move 2s infinite;
  z-index: -2;
}

#cloud-2:after {
  content: "";
  background: rgba(255, 255, 255);
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  top: -50px;
  left: 50px;
}

#cloud-2:before {
  content: "";
  background: rgba(255, 255, 255);
  position: absolute;
  width: 170px;
  height: 150px;
  border-radius: 50%;
  top: -90px;
  right: 40px;
}

/*clouds-3*/

#cloud-3 {
  background: rgba(255, 255, 255);
  width: 300px;
  height: 100px;
  border-radius: 150px;
  box-shadow: 10px 10px rgba(0, 0, 0, 0.2);
  animation: move 2s infinite;
  z-index: -2;
}

#cloud-3:after {
  content: "";
  background: rgba(255, 255, 255);
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  top: -50px;
  left: 50px;
}

#cloud-3:before {
  content: "";
  background: rgba(255, 255, 255);
  position: absolute;
  width: 170px;
  height: 150px;
  border-radius: 50%;
  top: -90px;
  right: 40px;
}

/*clouds-certification*/

#cloud-certification {
  background: rgba(255, 255, 255);
  width: 1000px;
  height: 300px;
  border-radius: 150px;
  margin-top: 120px;
  box-shadow: 10px 10px rgba(0, 0, 0, 0.2);
  animation: move 4s infinite;
  z-index: -2;
}

#cloud-certification:after {
  content: "";
  background: rgba(255, 255, 255);
  position: absolute;
  z-index: -2;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  top: -120px;
  left: 60px;
}

#cloud-certification:before {
  content: "";
  background: rgba(255, 255, 255);
  position: absolute;
  z-index: -2;
  width: 600px;
  height: 400px;
  border-radius: 50%;
  top: -180px;
  right: 80px;
}

/*cloud-skills-one*/

#cloud-skills-one {
  background: rgba(255, 255, 255);
  width: 900px;
  height: 220px;
  border-radius: 150px;
  margin-top: 120px;
  box-shadow: 10px 10px rgba(0, 0, 0, 0.2);
  animation: move 4s infinite;
  z-index: -2;
}

#cloud-skills-one:after {
  content: "";
  background: rgba(255, 255, 255);
  position: absolute;
  z-index: -2;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  top: -160px;
  left: 480px;
}

#cloud-skills-one:before {
  content: "";
  background: rgba(255, 255, 255);
  position: absolute;
  z-index: -2;
  width: 550px;
  height: 460px;
  border-radius: 50%;
  top: -220px;
  right: 270px;
}

.csImage {
  height: auto;
  width: 35vw;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2vw;
  transform: translateY(-20px);
}

.techStack {
  height: 40px;
  width: auto;
  display: flex;
}

.nextjslogo {
  background-color: white;
  padding: 2px;
  border-radius: 5px;
}

.privateLogo {
  height: auto;
  width: 200px;
}

.airtablelogo {
  margin-left: 1.1vw;
  margin-right: 0.6vw;
}

.cottageLogo {
  height: auto;
  width: 330px;
}

.links:hover {
  color: white;
}

.pp_container:hover .overlay {
  -webkit-animation: rotate-out-2-br-cw 0.6s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: rotate-out-2-br-cw 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.overlay {
  -webkit-animation: rotate-in-2-br-ccw 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: rotate-in-2-br-ccw 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.we_container:hover .overlayWorkExp {
  -webkit-animation: rotate-out-2-bl-ccw 0.6s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: rotate-out-2-bl-ccw 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.overlayWorkExp {
  -webkit-animation: rotate-in-2-bl-cw 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: rotate-in-2-bl-cw 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.info_container {
  text-align: center;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-10-26 22:44:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-out-2-bl-ccw
 * ----------------------------------------
 */

@-webkit-keyframes rotate-in-2-br-ccw {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes rotate-in-2-br-ccw {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@-webkit-keyframes rotate-out-2-br-cw {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes rotate-out-2-br-cw {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }
}

@-webkit-keyframes rotate-in-2-bl-cw {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    opacity: 1;
  }
}
@keyframes rotate-in-2-bl-cw {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    opacity: 1;
  }
}

@-webkit-keyframes rotate-out-2-bl-ccw {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    opacity: 0;
  }
}
@keyframes rotate-out-2-bl-ccw {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    opacity: 0;
  }
}

/*cloud-skills-two*/

#cloud-skills-two {
  background: rgba(255, 255, 255);
  width: 900px;
  height: 240px;
  border-radius: 150px;
  margin-top: 120px;
  box-shadow: 10px 10px rgba(0, 0, 0, 0.2);
  animation: move 4s infinite;
  z-index: -2;
}

#cloud-skills-two:after {
  content: "";
  background: rgba(255, 255, 255);
  position: absolute;
  z-index: -2;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  top: -120px;
  left: 30px;
}

#cloud-skills-two:before {
  content: "";
  background: rgba(255, 255, 255);
  position: absolute;
  z-index: -2;
  width: 500px;
  height: 400px;
  border-radius: 50%;
  top: -220px;
  right: 70px;
}

#typerwriter {
  font-size: 2rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

@keyframes move {
  0% {
    transform: translatex(0);
  }
  50% {
    transform: translatex(-40px);
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2022-10-3 21:12:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- *25
/**
 * ----------------------------------------
 * animation flip-horizontal-fwd
 * ----------------------------------------
 */
@keyframes flip-horizontal-fwd {
  0% {
    transform: translateZ(160px) rotateX(-180deg);
  }
  100% {
    transform: translateZ(0) rotateX(0);
  }
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(1.2);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(whitesmoke, 0);
  }
  100% {
    transform: scale(1.2);
    box-shadow: 0 0 0 0 rgba(whitesmoke, 0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.2);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(whitesmoke, 0);
  }
  100% {
    transform: scale(1.2);
    box-shadow: 0 0 0 0 rgba(whitesmoke, 0);
  }
}

@-webkit-keyframes text-pop-up-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0 1px 0 whitesmoke, 0 2px 0 whitesmoke, 0 3px 0 whitesmoke,
      0 4px 0 whitesmoke, 0 5px 0 whitesmoke, 0 6px 0 whitesmoke,
      0 7px 0 whitesmoke, 0 8px 0 whitesmoke, 0 9px 0 whitesmoke,
      0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0 0.75px 0 #bd8537, 0 1.5px 0 #bd8537, 0 2.25px 0 #bd8537,
      0 3px 0 #bd8537, 0 3.75px 0 #bd8537, 0 4.5px 0 #bd8537, 0 5.25px 0 #bd8537,
      0 6px 0 #bd8537, 0 6.75px 0 #bd8537, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}

@-webkit-keyframes text-pop-up-top-scroll-arrow {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #39395f, 0 2px 0 #39395f, 0 3px 0 #39395f,
      0 4px 0 #39395f, 0 5px 0 #39395f, 0 6px 0 #39395f, 0 7px 0 #39395f,
      0 8px 0 #39395f, 0 9px 0 #39395f, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-top-scroll-arrow {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #39395f, 0 2px 0 #39395f, 0 3px 0 #39395f,
      0 4px 0 #39395f, 0 5px 0 #39395f, 0 6px 0 #39395f, 0 7px 0 #39395f,
      0 8px 0 #39395f, 0 9px 0 #39395f, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}

@-webkit-keyframes text-pop-up-top-no-depth {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0 0.25px 0 whitesmoke, 0 0.5px 0 whitesmoke,
      0 0.75px 0 whitesmoke, 0 1px 0 whitesmoke, 0 1.25px 0 whitesmoke,
      0 1.5px 0 whitesmoke, 0 1.75px 0 whitesmoke, 0 2px 0 whitesmoke,
      0 2.25px 0 whitesmoke, 0 12px 7.5px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-top-no-depth {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0 0.25px 0 whitesmoke, 0 0.5px 0 whitesmoke,
      0 0.75px 0 whitesmoke, 0 1px 0 whitesmoke, 0 1.25px 0 whitesmoke,
      0 1.5px 0 whitesmoke, 0 1.75px 0 whitesmoke, 0 2px 0 whitesmoke,
      0 2.25px 0 whitesmoke, 0 12px 7.5px rgba(0, 0, 0, 0.3);
  }
}

@-webkit-keyframes front-end {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0 0.25px 0 whitesmoke, 0 0.5px 0 whitesmoke,
      0 0.75px 0 whitesmoke, 0 1px 0 whitesmoke, 0 1.25px 0 whitesmoke,
      0 1.5px 0 whitesmoke, 0 1.75px 0 whitesmoke, 0 2px 0 whitesmoke,
      0 2.25px 0 whitesmoke, 0 12px 7.5px rgba(0, 0, 0, 0.3);
  }
}
@keyframes front-end {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0 0.25px 0 whitesmoke, 0 0.5px 0 whitesmoke,
      0 0.75px 0 whitesmoke, 0 1px 0 whitesmoke, 0 1.25px 0 whitesmoke,
      0 1.5px 0 whitesmoke, 0 1.75px 0 whitesmoke, 0 2px 0 whitesmoke,
      0 2.25px 0 whitesmoke, 0 12px 7.5px rgba(0, 0, 0, 0.3);
  }
}

@-webkit-keyframes rotate-scale-down {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
    opacity: 1;
  }
  25% {
    -webkit-transform: scale(0.75) rotateZ(90deg);
    transform: scale(0.75) rotateZ(90deg);
    opacity: 0.5;
  }
  50% {
    -webkit-transform: scale(0.5) rotateZ(180deg);
    transform: scale(0.5) rotateZ(180deg);
    opacity: 0;
  }
  75% {
    -webkit-transform: scale(0.25) rotateZ(270deg);
    transform: scale(0.25) rotateZ(270deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(0) rotateZ(360deg);
    transform: scale(0) rotateZ(360deg);
    opacity: 0;
  }
}
@keyframes rotate-scale-down {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
    opacity: 1;
  }
  25% {
    -webkit-transform: scale(0.75) rotateZ(90deg);
    transform: scale(0.75) rotateZ(90deg);
    opacity: 0.5;
  }
  50% {
    -webkit-transform: scale(0.5) rotateZ(180deg);
    transform: scale(0.5) rotateZ(180deg);
    opacity: 0;
  }
  75% {
    -webkit-transform: scale(0.25) rotateZ(270deg);
    transform: scale(0.25) rotateZ(270deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(0) rotateZ(360deg);
    transform: scale(0) rotateZ(360deg);
    opacity: 0;
  }
}
