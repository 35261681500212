.square-flip{
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;

  -webkit-transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  -ms-transform: perspective(1000px);
  transform: perspective(1000px);

  -webkit-transform-style: preserve-3d; 
  -moz-transform-style: preserve-3d; 
  -ms-transform-style: preserve-3d; 
  transform-style: preserve-3d; 

  /*border:1px solid #efefef;*/
  
  position: relative;
  text-align: center;
  margin-left: auto;
  margin-right: auto;


  }
  .square-flip{
    width:60vw;
    height:30vw;
  }
  .square,.square2{
    background-color: rgba(245, 245, 245, 0.4);
border-radius: 20px;
    box-shadow:0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
    width:100%;
    height:100%;
  }
  .square{
    background-size: cover;
    background-position:center center;

    -ms-transition: transform 1.2s cubic-bezier(.5,.3,.3,1);
        transition: transform 1.2s cubic-bezier(.5,.3,.3,1);
        -webkit-transition: transform 1.2s cubic-bezier(.5,.3,.3,1);
    overflow: hidden;

    position:absolute;
    top:0;


    -webkit-backface-visibility: hidden; 
    backface-visibility: hidden;
  }
  .square-flip .square{
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    transform-style: preserve-3d;
    z-index:1;
  }
  .square-flip:hover .square{
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    transform-style: preserve-3d;
  }


  .square2{

    background-size: cover;
    background-position:center center;

    -ms-transition: transform 1.2s cubic-bezier(.5,.3,.3,1);
        transition: transform 1.2s cubic-bezier(.5,.3,.3,1);
        -webkit-transition: transform 1.2s cubic-bezier(.5,.3,.3,1);
    overflow: hidden;

    position:absolute;
    top:0;

    -webkit-backface-visibility: hidden; 
    backface-visibility: hidden;
  }
  .square-flip .square2{
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
    transform-style: preserve-3d;
    z-index:1;
  }
  .square-flip:hover .square2{
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    transform-style: preserve-3d;
  }

  /*Square content*/
  .square-container{
    padding:40px;
    text-align:center;
    position:relative;
    top:50%;

    -ms-transition: transform 1.2s cubic-bezier(.5,.3,.3,1);
        transition: transform 1.2s cubic-bezier(.5,.3,.3,1);
        -webkit-transition: transform 1.2s cubic-bezier(.5,.3,.3,1);

  
    -webkit-transform: translateY(-50%) translateX(0px)  scale(1);
    -ms-transform: translateY(-50%) translateX(0px)  scale(1);
transform: translateY(-50%) translateX(0px)  scale(1);
    transform-style: preserve-3d;
    z-index:2;
  }
  .square-flip:hover .square-container{
    
    -webkit-transform: translateY(-50%) translateX(-650px)  scale(.88);
    -ms-transform: translateY(-50%) translateX(-650px)  scale(.88);
    transform: translateY(-50%) translateX(-650px)  scale(.88);
    transform-style: preserve-3d;

  }

  .square-container2{
    padding:40px;
    text-align:center;
    position:relative;
    top:50%;

    -ms-transition: transform 1.2s cubic-bezier(.5,.3,.3,1);
        transition: transform 1.2s cubic-bezier(.5,.3,.3,1);
        -webkit-transition: transform 1.2s cubic-bezier(.5,.3,.3,1);

    
    -webkit-transform: translateY(-50%) translateX(650px) translateZ(60px) scale(.88);
    -ms-transform: translateY(-50%) translateX(650px) translateZ(60px) scale(.88);
    transform: translateY(-50%) translateX(650px) translateZ(60px) scale(.88);

    transform-style: preserve-3d;
    z-index:2;
  }
  .square-flip:hover .square-container2{
    
    -webkit-transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
    -ms-transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
    transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
    transform-style: preserve-3d;
  }



  /*Style text*/
  .square-flip h2{
    color:white;
    font-family: "Open Sans";
    font-weight:700;
    font-size:22px;
  }
  .square-flip h3{
    color:white;
    font-family: "Open Sans";
    font-weight:500;
    font-size:16px;
    line-height:26px;
  }
  /*Elements*/
  .flip-overlay{
    display:block;
    background:rgba(0,0,0,0.95);
    border-radius: 20px;
    
    width:100%;
    height:100%;
    position:absolute;
    top:0;
  }
  .align-center{
    margin:auto auto;
  }
  .kallyas-button{
    display:block;
    width:fit-content;
    padding:22px 44px;
    color:#28282B;
    margin:auto auto;
    margin-top: 70px;
    border-radius: 60px;
    text-decoration:none;
    box-shadow:0px 6px 10px -2px rgb(16 228 247 / 20%), 0px 12px 20px 0px rgb(16 228 247 / 14%), 0px 2px 36px 0px rgb(16 228 247 / 12%);
    
	-webkit-animation: text-pop-up-top-card 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: text-pop-up-top-card 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e9f6fd+0,d3eefb+100 */
background: #e9f6fd; /* Old browsers */
background: -moz-linear-gradient(top,  #e9f6fd 0%, #d3eefb 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  #e9f6fd 0%,#d3eefb 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  #e9f6fd 0%,#d3eefb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9f6fd', endColorstr='#d3eefb',GradientType=0 ); /* IE6-9 */
}

@-webkit-keyframes text-pop-up-top-card {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
            text-shadow: 0 .5px 0 #28282B, 01.2px 0 #28282B, 0 1.5px 0 #28282B, 0 2px 0 #28282B, 0 2.5px 0 #28282B, 0 3px 0 #28282B, 0 3.5px 0 #28282B, 0 4px 0 #28282B, 0 4.5px 0 #28282B, 0 25px 15px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-top-card {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
            text-shadow: 0 .375px 0 #28282B, 0 .75px 0 #28282B, 0 2.251.125px 0 #28282B, 0 1.5px 0 #28282B, 0 1.85px 0 #28282B, 0 2.25px 0 #28282B, 0 2.65px 0 #28282B, 0 3px 0 #28282B, 0 3.375px 0 #28282B, 0 25px 15px rgba(0, 0, 0, 0.3);
  }
}


  
  /*You can delete this style*/
  .centerflipcards{
    display:block;
    width:1330px;
    height:440px;
    text-align:center;
    margin:0 auto;
    margin-top:25px;
  }
.clearfix{clear:both;}
.centerflipcards p{
    font-family:"Open Sans";
    font-size:13px;
    margin-top:10px;
    font-weight:700;
}